import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchTimeout from "fetch-timeout";
import { fetchTimeout as fetchTest } from "../utils/mockup";
import { toast } from "react-toastify";

import { getWifiUrl, portalSimulator, castSimulator } from "../actions/config";
import usePortalData from "./usePortalData";
import { authMarketing, updatePortalSession, getPortalSession } from "../actions/portal";
import {
    useSignInWifiMutation,
    useUpdateCommercialConsentMutation,
    useUpdateUserRadiusMutation,
    useSendEmailValidationMutation,
    useValidateEmailMutation,
    useSignUpWifiMutation,
    useGetPortalTokenFromSession,
} from "graphql/useWifi";
import { v5 as uuidv5 } from "uuid";

const useWifiService = () => {
    const dispatch = useDispatch();
    const fetch = portalSimulator() ? fetchTest : fetchTimeout;

    const [loading, setLoading] = useState(false);
    const { sessionKey, chainRef, projectRef, token, error } = usePortalData();
    const { signInWifi: executeSignIn } = useSignInWifiMutation();
    const { updateCommercialConsent: executeUpdateCommercialConsent } = useUpdateCommercialConsentMutation();
    const { updateUserRadius: executeUpdateUserRadius } = useUpdateUserRadiusMutation();
    const { sendEmailValidation: executeSendEmailValidation } = useSendEmailValidationMutation();
    const { validateEmail: executeValidateEmail } = useValidateEmailMutation();
    const { signUpWifi: executeSignUpWifi } = useSignUpWifiMutation();
    const { getPortalToken } = useGetPortalTokenFromSession();

    const [lastCallError, setLastCallError] = useState(null);

    const session = useSelector(getPortalSession);

    //TODO mostrar mensaje (traducido) segun error.code | buscar tabla de equivalencias
    // 500 - internal server error
    // 403 - limite de dispositivos por usuario alcanzado
    // 401 - Authentication failed (login fail)

    const request = (method, url, json, parseResponse, parseError) => {
        fetch(
            getWifiUrl() + "/v0/" + url,
            {
                method: method,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
                body: json ? JSON.stringify(json) : null,
            },
            20000
        )
            .then((http) => {
                if (!http.ok || http.status == 204) {
                    const err = new Error(http.statusText);
                    err.code = "WFS" + http.status;
                    err.stack = `[${method}] ${url}\n\n${json ? JSON.stringify(json) : null}\n\n${token}`;
                    throw err;
                }
                return http.json();
            })
            .then((body) => {
                return parseResponse ? parseResponse(body) : body;
            })
            .catch((err) => {
                if (!(err instanceof Error) && typeof err === "object") {
                    err = new Error(JSON.stringify(err));
                }
                if (typeof err === "string") {
                    err = new Error(err);
                }
                toast.error(err.message);
                err.stack = `[${method}] ${url}\n\n${json ? JSON.stringify(json) : null}\n\n${token}\n\n${err.stack}`;
                setLastCallError(err);
                if (parseError) {
                    parseError(err);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const signIn = async ({ type, email, google, room, surname, code, marketingAccepted }) => {
        setLoading(true);
        let post;
        try {
            post = {
                LoginType: type,
                SessionKey: sessionKey,
                MarketingAccepted: marketingAccepted,
            };
            switch (type) {
                case "pms":
                    post.Room = room;
                    post.Surname = surname;
                    post.Email = email;
                    break;
                case "code":
                    post.Code = code;
                    post.Email = email;
                    break;
                case "email":
                    post.RrssToken = google;
                    post.Email = email;
                    break;
                case "open":
                    break;
                default:
                    throw new Error("Unknown login type " + type);
            }
        } catch (error) {
            setLoading(false);
            return Promise.reject(error);
        }

        dispatch(authMarketing(marketingAccepted));
        return executeSignIn({
            variables: {
                surname: post?.Surname || null,
                rrssToken: post?.RrssToken || null,
                email: post?.Email || null,
                room: post?.Room || null,
                code: post?.Code || null,
                marketingAccepted: marketingAccepted || false,
                chainRef: chainRef || null,
                loginType: type || null,
                sessionKey: sessionKey || null,
                hotelRef: projectRef || null,
            },
        })
            .then((response) => {
                if (response.errors) {
                    console.error("GraphQL Errors:", response.errors);
                    return response;
                }
                if (response.data) {
                    if (response.data.signInWifi?.error?.msg) {
                        toast.error(response.data.signInWifi.error.msg);
                        return response;
                    }
                    const loyaltyRef = response?.data?.signInWifi?.loyalty?.ref || null;
                    const loyaltyEmail = response?.data?.signInWifi?.loyalty?.email || null;
                    const verify = response?.data?.signInWifi?.email?.verify || null;
                    const rooms = response?.data?.signInWifi?.room?.rooms || null;
                    const googleAccessToken = response?.data?.signInWifi?.rrss?.googleAccessToken || null;
                    const redirect = response?.data?.signInWifi?.redirect || null;
                    const mikrotik = response?.data?.signInWifi?.login || null;
                    dispatch(
                        updatePortalSession({
                            email: loyaltyEmail || email,
                            signup: false,
                            ref: loyaltyRef,
                            google: googleAccessToken,
                            rooms,
                            verify,
                            hash: null,
                            verified: null,
                            mikrotik,
                            redirect,
                        })
                    );
                }
                return response;
            })
            .catch((error) => {
                console.error("Network or Unexpected Error:", error.message);
                toast.error("A network error occurred, please try again.");
                return Promise.reject(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateCommercialConsent = (accepted = false) => {
        setLoading(true);

        let variables = {
            marketingAccepted: accepted,
            ref: session.ref,
            chainRef: chainRef || null,
            sessionKey: sessionKey || null,
            hotelRef: projectRef || null,
        };

        dispatch(authMarketing(accepted));

        return new Promise((resolve, reject) => {
            executeUpdateCommercialConsent({ variables })
                .then((response) => {
                    if (response.errors) {
                        console.error("GraphQL Errors:", response.errors);
                        reject(response.errors);
                        return;
                    }

                    const returnedRef = response?.data?.updateCommercialConsent?.ref || null;
                    const success = returnedRef === session.ref;

                    dispatch(updatePortalSession({ marketing: success }));

                    if (!success) {
                        reject(new Error("Response ref does not match session ref."));
                        return;
                    }

                    resolve(response);
                })
                .catch((error) => {
                    console.error("Network or Unexpected Error:", error.message);
                    reject(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const chromecastPairing = () => {
        const simulator = castSimulator();
        const pairingRoom = session.room;
        const pairingURL = pairingRoom
            ? simulator
                ? "/pairing/?tokenMobile=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9"
                : pairingRoom.ccUrl
            : null;
        const fetch = simulator ? fetchTest : fetchTimeout;

        return new Promise((resolve, reject) => {
            setLoading(true);

            const parseResponse = (response) => {
                const hasChromecast = response && response.success && response.ccInfo && response.ccInfo.length > 0;
                setLoading(false);
                if (hasChromecast) {
                    resolve(response);
                } else {
                    reject(response);
                }
            };
            const parseError = (e) => {
                reject(e);
            };

            fetch(pairingURL, null, 30000)
                .then((http) => {
                    if (!http.ok || http.status == 204) {
                        throw new Error("HTTP " + http.status + " - " + http.statusText);
                    }
                    return http.json();
                })
                .then((body) => {
                    setLoading(false);
                    return parseResponse ? parseResponse(body) : body;
                })
                .catch((err) => {
                    setLoading(false);
                    let msg = err.message || err || "Unknown error";
                    toast.error(msg);
                    if (parseError) parseError(err);
                });
        });
    };

    const updateUserRadius = () => {
        return new Promise((resolve, reject) => {
            setLoading(true);

            let variables = {
                dst: session.redirect.pwa ? session.getPWAURL(projectRef) : session.redirect.url,
                username: session.mikrotik.urlData.username,
                password: session.mikrotik.urlData.password,
                sessionKey: sessionKey,
                hotelRef: projectRef || null,
                chainRef: chainRef || null,
            };

            executeUpdateUserRadius({ variables })
                .then((response) => {
                    if (response.errors) {
                        console.error("GraphQL Errors:", response.errors);
                        reject(response.errors);
                        return;
                    }

                    resolve(response);
                })
                .catch((error) => {
                    console.error("Network or Unexpected Error:", error.message);
                    reject(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const sendEmailValidation = () => {
        return new Promise((resolve, reject) => {
            setLoading(true);

            const link = session.returnURL(chainRef, projectRef, sessionKey);

            let variables = {
                sessionKey: sessionKey,
                ref: session.ref,
                link: link,
                hotelRef: projectRef || null,
                chainRef: chainRef || null,
            };

            executeSendEmailValidation({ variables })
                .then((response) => {
                    if (response.errors) {
                        console.error("GraphQL Errors:", response.errors);
                        setLoading(false);
                        reject(response.errors);
                        return;
                    }

                    const hash = response?.data?.sendEmailValidation?.hash || null;

                    dispatch(updatePortalSession({ hash, verified: null }));

                    setLoading(false);
                    if (hash) resolve();
                    else reject(new Error("No hash returned from GraphQL mutation."));
                })
                .catch((error) => {
                    console.error("Network or Unexpected Error:", error.message);
                    setLoading(false);
                    reject(error);
                });
        });
    };

    const validateEmail = (code) => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            const hash = session.hash;
            const email = session.email;

            if (!hash) {
                setLoading(false);
                reject("empty hash");
                return;
            }

            let variables = {
                sessionKey: sessionKey,
                code: code,
                email: email ? email.toLowerCase() : null,
                hash: hash,
                ref: session.ref,
                hotelRef: projectRef || null,
                chainRef: chainRef || null,
            };

            executeValidateEmail({ variables })
                .then((response) => {
                    if (response.errors) {
                        console.error("GraphQL Errors:", response.errors);
                        setLoading(false);
                        reject(response.errors);
                        return;
                    }

                    const success = response?.data?.validateEmail?.response?.valid || false;
                    const mikrotik = response?.data?.validateEmail?.login || null;

                    dispatch(updatePortalSession({ hash, verified: success ? code : null, mikrotik }));

                    setLoading(false);
                    if (success) resolve();
                    else reject("invalid code"); // TODO: Traducir esto si es necesario
                })
                .catch((error) => {
                    console.error("Network or Unexpected Error:", error.message);
                    setLoading(false);
                    reject(error);
                });
        });
    };

    const signUp = ({ email, name, surname, birth, country, marketingAccepted }) => {
        setLoading(true);

        let variables = {
            marketingAccepted: marketingAccepted,
            email: email ? email.toLowerCase() : null,
            name: name,
            surname: surname,
            birthDate: birth || null,
            countryRef: country ? country.toUpperCase() : null,
            sessionKey: sessionKey,
            hotelRef: projectRef || null,
            chainRef: chainRef || null,
        };

        return new Promise((resolve, reject) => {
            executeSignUpWifi({ variables })
                .then((response) => {
                    if (response.errors) {
                        console.error("GraphQL Errors:", response.errors);
                        setLoading(false);
                        reject(response.errors);
                        return;
                    }

                    const success = response?.data?.signUpWifi?.ref || null;
                    if (success) {
                        const loyaltyRef = response?.data?.signUpWifi?.ref;
                        const loyaltyEmail = response?.data?.signUpWifi?.email;

                        dispatch(
                            updatePortalSession({
                                email: loyaltyEmail || email,
                                ref: loyaltyRef,
                                google: null,
                                signup: true,
                            })
                        );

                        resolve(response);
                    } else {
                        reject(new Error("No reference returned from GraphQL mutation."));
                    }
                })
                .catch((error) => {
                    console.error("Network or Unexpected Error:", error.message);
                    reject(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const getPortalTokenFromSession = (sessionKey) => {
        setLoading(true);

        return new Promise((resolve, reject) => {
            getPortalToken({
                variables: { sessionKey },
            })
                .then((e) => {
                    if (e.data.getPortalTokenFromSession) {
                        const {
                            chainRef = "",
                            projectRef = "",
                            session = "",
                            accessTypes = [],
                        } = e.data.getPortalTokenFromSession;
                        const newObjectData = {
                            ChainRef: chainRef,
                            ProjectRef: projectRef,
                            Session: session,
                            AccessTypes: accessTypes.map(({ name, rrss, additionalData }) => ({
                                Name: name,
                                RRSS: rrss,
                                AdditionalData: additionalData || {},
                            })),
                        };
                        resolve(newObjectData);
                    } else {
                        console.error("No data received");
                        reject(new Error("No data received"));
                    }
                })
                .catch((e) => {
                    console.error("Error fetching token:", e);
                    reject(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };
    return {
        signIn,
        signUp,
        getPortalTokenFromSession,
        chromecastPairing,
        updateCommercialConsent,
        sendEmailValidation,
        updateUserRadius,
        validateEmail,
        loading,
        error: lastCallError || error,
        ignoreError: () => {
            setLastCallError(null);
        },
    };
};

export default useWifiService;
