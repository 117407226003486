import gql from "graphql-tag";

export const GET_PORTAL_TOKEN_FROM_SESSION = gql`
    query getPortalTokenFromSession($sessionKey: String!) {
        getPortalTokenFromSession(sessionKey: $sessionKey) {
            chainRef
            projectRef
            session
            accessTypes {
                additionalData
                name
                rrss
            }
        }
    }
`;
