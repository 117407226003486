import {
    SIGN_IN_WIFI,
    UPDATE_COMMERCIAL_CONSENT,
    UPDATE_USER_RADIUS,
    SEND_EMAIL_VALIDATION,
    VALIDATE_EMAIL,
    SIGN_UP_WIFI,
} from "./mutations/wifi";
import { useMutation, useLazyQuery } from "@apollo/client";

import { GET_PORTAL_TOKEN_FROM_SESSION } from "./queries/wifi";

export const useSignInWifiMutation = () => {
    const [signInWifi] = useMutation(SIGN_IN_WIFI.query);
    return { signInWifi };
};

export const useUpdateCommercialConsentMutation = () => {
    const [updateCommercialConsent] = useMutation(UPDATE_COMMERCIAL_CONSENT);
    return { updateCommercialConsent };
};

export const useUpdateUserRadiusMutation = () => {
    const [updateUserRadius] = useMutation(UPDATE_USER_RADIUS);
    return { updateUserRadius };
};

export const useSendEmailValidationMutation = () => {
    const [sendEmailValidation] = useMutation(SEND_EMAIL_VALIDATION);
    return { sendEmailValidation };
};

export const useValidateEmailMutation = () => {
    const [validateEmail] = useMutation(VALIDATE_EMAIL);
    return { validateEmail };
};

export const useSignUpWifiMutation = () => {
    const [signUpWifi] = useMutation(SIGN_UP_WIFI);
    return { signUpWifi };
};

export const useGetPortalTokenFromSession = () => {
    const [getPortalToken, { data, loading, error }] = useLazyQuery(GET_PORTAL_TOKEN_FROM_SESSION, {
        fetchPolicy: "network-only",
    });

    return {
        getPortalToken,
        data,
        loading,
        error,
    };
};
