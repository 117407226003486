import React, { useEffect, useState } from "react";

import { configToCSS, parseImageConfig } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";
import useInterval from "../../../hooks/useInterval";

const UseWidgetCarousel = ({ config, id, deviceType }) => {
    const { cfg } = useWidget({
        data: config ? config.carousel : null,
        styles: config ? config.styles : null,
        parseConfig,
    });
    const [active, setActive] = useState(0);
    const [startX, setStartX] = useState(0);
    const [difference, setDifference] = useState();
    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "transparent";
    }, [id]);

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            if (difference > 10) {
                if (active != cfg.images.length - 1) {
                    setActive((prevIndex) => prevIndex + 1);
                }
            } else if (difference < -10) {
                if (active != 0) {
                    setActive((prevIndex) => prevIndex - 1);
                }
            }
        }, 50);
        return () => {
            clearTimeout(timeoutID);
        };
    }, [difference]);

    useInterval(async () => {
        if (cfg.images && cfg.images.length > 1 && cfg.automatic) {
            const next = active + 1 >= cfg.images.length ? 0 : active + 1;
            setActive(next);
        }
    }, cfg?.interval * 1000);

    const handleTouchStart = (e) => {
        if (e.touches) {
            setStartX(e.touches[0].clientX);
        } else {
            setStartX(e.clientX);
        }
    };

    const handleTouchMove = (e) => {
        const currentX = e.touches ? e.touches[0].clientX : e.clientX;
        const difference = startX - currentX;
        setDifference(difference);
    };

    return (
        <div
            onTouchMove={(e) => {
                if (!cfg.automatic) {
                    handleTouchMove(e);
                }
            }}
            onTouchStart={(e) => {
                if (!cfg.automatic) {
                    handleTouchStart(e);
                }
            }}
            onMouseUp={(e) => {
                if (!cfg.automatic) {
                    handleTouchMove(e);
                }
            }}
            onMouseDown={(e) => {
                if (!cfg.automatic) {
                    handleTouchStart(e);
                }
            }}
            className={`flex w-full h-full bg-center bg-no-repeat relative`}
            id={id}
            style={configToCSS({ ...cfg, bgImage: cfg.images[active] }, deviceType)}
        >
            {!cfg.automatic && cfg.images.length > 1 && (
                <div
                    className="flex justify-center items-center gap-2 absolute"
                    style={{ width: "fit-content", bottom: "10px", left: 0, right: 0, margin: "0 auto" }}
                >
                    {cfg.images.map((_, index) => {
                        const style =
                            index == active
                                ? configToCSS({ ...cfg.bullets }, deviceType)
                                : { borderColor: configToCSS({ ...cfg.bullets }, deviceType)?.borderColor };

                        const size =
                            active == index ||
                            index == active + 1 ||
                            index == active - 1 ||
                            (active == 0 && index == 2) ||
                            (active == cfg.images.length - 1 && index == cfg.images.length - 3)
                                ? { width: "10px", height: "10px" }
                                : { width: "6px", height: "6px" };
                        return (
                            <button
                                className=" rounded-full border"
                                style={{
                                    ...style,
                                    ...size,
                                }}
                                onClick={() => {
                                    setActive(index);
                                }}
                            ></button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const parseConfig = ({ data, styles, lang }) => {
    let images = [];
    if (data.images) {
        data.images.map((img) => {
            images.push({
                src: parseImageConfig(img, lang),
                adjust: data.adjust || "cover",
            });
            return img;
        });
    }

    return {
        images: images,
        interval: data?.duration && data?.duration >= 5 ? data?.duration : 5,
        automatic: data.automatic,
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
        bullets: {
            bgColor: styles?.items?.bullets?.fgColor,
            border: { color: styles?.items?.bullets?.fgColor },
        },
    };
};

export default UseWidgetCarousel;
