import gql from "graphql-tag";

export const SIGN_IN_WIFI = {
    query: gql`
        mutation SignInWifi(
            $surname: String
            $rrssToken: String
            $email: String
            $countryRef: String
            $hotelRef: ID!
            $room: String
            $birth: String
            $hotspot: String
            $code: String
            $marketingAccepted: Boolean
            $chainRef: ID!
            $loginType: String
            $sessionKey: String
        ) {
            signInWifi(
                surname: $surname
                rrssToken: $rrssToken
                email: $email
                countryRef: $countryRef
                hotelRef: $hotelRef
                room: $room
                birth: $birth
                hotspot: $hotspot
                code: $code
                marketingAccepted: $marketingAccepted
                chainRef: $chainRef
                loginType: $loginType
                sessionKey: $sessionKey
            ) {
                email {
                    hash
                    verify
                }
                error {
                    code
                    msg
                    errString
                }
                login {
                    url
                    urlData {
                        dst
                        password
                        username
                    }
                }
                loyalty {
                    birthDate
                    countryRef
                    email
                    emailVerified
                    gender
                    id
                    languageRef
                    lastAccess
                    locked
                    marketingAccepted
                    name
                    phone
                    photo
                    ref
                    sessionValidDate
                    signupApp
                    signupDate
                    signupWifi
                    surname
                    termsAccepted
                }
                redirect {
                    url
                    pwa
                }
                room {
                    rooms {
                        cc
                        ccUrl
                        code
                        name
                        number
                    }
                }
                rrss {
                    googleAccessToken
                }
                success
            }
        }
    `,
};

export const UPDATE_COMMERCIAL_CONSENT = gql`
    mutation UpdateCommercialConsent(
        $chainRef: ID!
        $sessionKey: String!
        $ref: String!
        $marketingAccepted: Boolean
        $hotelRef: ID!
    ) {
        updateCommercialConsent(
            chainRef: $chainRef
            sessionKey: $sessionKey
            ref: $ref
            marketingAccepted: $marketingAccepted
            hotelRef: $hotelRef
        ) {
            marketingAccepted
            ref
        }
    }
`;

export const UPDATE_USER_RADIUS = gql`
    mutation UpdateUserRadius(
        $hotelRef: ID!
        $chainRef: ID!
        $sessionKey: String!
        $username: String!
        $password: String!
        $dst: String!
    ) {
        updateUserRadius(
            hotelRef: $hotelRef
            chainRef: $chainRef
            sessionKey: $sessionKey
            username: $username
            password: $password
            dst: $dst
        ) {
            username
        }
    }
`;

export const SEND_EMAIL_VALIDATION = gql`
    mutation SendEmailValidation($ref: String!, $link: String!, $hotelRef: ID!, $chainRef: ID!, $sessionKey: String!) {
        sendEmailValidation(ref: $ref, link: $link, hotelRef: $hotelRef, chainRef: $chainRef, sessionKey: $sessionKey) {
            hash
            ref
        }
    }
`;

export const VALIDATE_EMAIL = gql`
    mutation ValidateEmail(
        $hotelRef: ID!
        $email: String
        $chainRef: ID!
        $sessionKey: String!
        $ref: String!
        $code: String!
        $hash: String!
    ) {
        validateEmail(
            hotelRef: $hotelRef
            email: $email
            chainRef: $chainRef
            sessionKey: $sessionKey
            ref: $ref
            code: $code
            hash: $hash
        ) {
            login {
                url
                urlData {
                    dst
                    password
                    username
                }
            }
            response {
                valid
            }
        }
    }
`;

export const SIGN_UP_WIFI = gql`
    mutation SignUpWifi(
        $email: String
        $chainRef: ID!
        $birthDate: String
        $countryRef: String
        $hotelRef: ID!
        $sessionKey: String!
        $marketingAccepted: Boolean!
        $name: String!
        $surname: String!
    ) {
        signUpWifi(
            email: $email
            chainRef: $chainRef
            birthDate: $birthDate
            countryRef: $countryRef
            hotelRef: $hotelRef
            sessionKey: $sessionKey
            marketingAccepted: $marketingAccepted
            name: $name
            surname: $surname
        ) {
            email
            ref
        }
    }
`;
