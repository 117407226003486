import React, { useEffect, useMemo, useState, useCallback, createContext } from "react";
import { useNotifications } from "graphql/useNotifications";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "actions/user";
import { useHello, useRooms, useLazyProfile } from "../graphql/useUser";
import pkg from "../../package.json";
import { FirebaseInit, FirebaseAskPermissions, FirebaseOnTokenRefresh } from "../firebase";
import { setPushToken } from "../actions/notifications";
import { logoutUser, getToken } from "../actions/user";
import { getAssetsUrl } from "actions/config";

export const NOTIF_ROOM_ADDED = "NOTIF_ROOM_ADDED";
export const NOTIF_JOIN_WIFI = "NOTIF_JOIN_WIFI";

export const NOTIF_KIND_ACCOUNT = "account"; // User account notifications
export const NOTIF_KIND_CHECKIN = "checkin"; // Check-in notifications
export const NOTIF_KIND_ZONES = "zones"; // Common Zones notifications
export const NOTIF_KIND_KEYS = "keys"; // Keys notifications
export const NOTIF_KIND_MESSAGES = "messages"; // Messages notifications

export const NOTIF_ACTION_VERIFY = "verify"; // User account verification
export const NOTIF_ACTION_COMPLETE = "complete"; // Pre-checkin available to complete
export const NOTIF_ACTION_WATCH = "watch"; // Show section
export const NOTIF_ACTION_UPDATE = "update"; // Update user account or keys
export const NOTIF_ACTION_LOGOUT = "logout"; // Logout user account
export const NOTIF_ACTION_NEW = "new"; // New message

export const NotificationContext = createContext({
    notifications: null,
    loading: null,
    error: null,
    displayError: () => {},
    displayInfo: () => {},
    getMessage: () => {},
});

// TODO replace all toast() in pwa to use this context

export const NotificationProvider = ({ children }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userToken = useSelector(getToken);
    const history = useHistory();
    const user = useSelector(getUser);
    const { hello } = useHello();
    const { update: updateRooms } = useRooms();
    const { lazyProfile } = useLazyProfile();

    const [lastDisplay, setLastDisplay] = useState({});
    const [showWifiNotification, setShowWifiNotification] = useState(true);

    const {
        query: loadNotifications,
        called,
        loading,
        error,
        data: notifications,
    } = useNotifications({ pollInterval: 60000 });

    const notificationHandle = useCallback(
        (data) => {
            const kind = data?.data?.Kind;
            const operation = data?.data?.Operation;
            const visible = data?.title || data?.body;

            const runAction = (kind, operation) => {
                switch (operation) {
                    case NOTIF_ACTION_VERIFY:
                        if (userToken) {
                            loadNotifications();
                        }
                        break;
                    case NOTIF_ACTION_LOGOUT:
                        if (userToken) {
                            dispatch(logoutUser(true));
                        }
                        break;
                    case NOTIF_ACTION_UPDATE:
                        switch (kind) {
                            case NOTIF_KIND_ACCOUNT:
                                if (userToken) {
                                    lazyProfile();
                                    updateRooms();
                                }
                                break;
                            case NOTIF_KIND_KEYS:
                                // Nothing to do (only native)
                                break;
                            default:
                        }
                        break;
                    case NOTIF_ACTION_WATCH:
                        switch (kind) {
                            case NOTIF_KIND_ACCOUNT:
                                history.push("/profile");
                                break;
                            case NOTIF_KIND_CHECKIN:
                                history.push("/checkin");
                                break;
                            case NOTIF_KIND_ZONES:
                                history.push("/notifications");
                                break;
                            case NOTIF_KIND_KEYS:
                                history.push("/keys");
                                break;
                            default:
                        }
                        break;
                    default:
                }
            };
            if (visible) {
                const notification = new Notification(data.title, {
                    body: data.body,
                    data: data.data,
                    tag: kind,
                    icon: getAssetsUrl() + "/icons/favicon.ico",
                    image: getAssetsUrl() + "/icons/icon-256x256.png",
                });
                notification.onclick = (event) => {
                    event.preventDefault();
                    window.focus();
                    runAction(kind, operation);
                    notification.close();
                };
            } else {
                runAction(kind, operation);
            }
        },
        [userToken, lazyProfile, loadNotifications]
    );

    useEffect(() => {
        if (!called && user) {
            loadNotifications();
        }
    }, [user]);

    useEffect(() => {
        if (error) {
            console.error(error);
        }
    }, [error]);

    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener("message", (event) => {
                if (event.data && event.data.isFirebaseMessaging) {
                    notificationHandle(event.data);
                }
            });
        }

        const firebaseConfig = window && window.appConfig && window.appConfig.firebaseConfig;
        if (!firebaseConfig) {
            console.error("Firebase configuration not found");
        } else {
            FirebaseInit(firebaseConfig);
            if (pkg.features.notifications) {
                FirebaseAskPermissions(notificationHandle).then((token) => {
                    if (token) {
                        dispatch(setPushToken(token));
                    }
                });
                FirebaseOnTokenRefresh().then((token) => {
                    if (token) {
                        dispatch(setPushToken(token));
                        hello({ variables: { push: token } });
                    }
                });
            }
        }
        return () => {};
    }, []);

    const contextValue = useMemo(
        () => ({
            notifications,
            loading,
            error,
            showWifiNotification,
            setShowWifiNotification,
            getMessage: (type, params) => {
                switch (type) {
                    case NOTIF_ROOM_ADDED:
                        return t("room added", { room: params?.number });
                    case NOTIF_JOIN_WIFI:
                        if (showWifiNotification) {
                            setShowWifiNotification(false);
                            return t("join hotel wifi", { hotel: params?.name });
                        }
                        return null;
                    default:
                        return null;
                }
            },
            displayInfo: (message, options) => {
                const ID = options?.id || message;
                const breakTime = options?.breakTime || 0;
                if (breakTime > 0) {
                    const last = lastDisplay ? lastDisplay[ID] : null;
                    console.log({
                        toastId: ID,
                        breakTime,
                        last,
                        now: Date.now(),
                        diff: last + breakTime - Date.now(),
                    });
                    if (last && last + breakTime - Date.now() > 0) {
                        // We take a break, not display this...
                        return;
                    }
                }

                toast(message, {
                    autoClose: 10000,
                    toastId: ID,
                    ...options,
                });
                setLastDisplay({
                    ...lastDisplay,
                    [ID]: Date.now(),
                });
            },
            displayError: (message, options) => {
                if (console.error) {
                    console.error(message);
                }
                toast.error(message, options);
            },
        }),
        [notifications, loading, showWifiNotification, error]
    );

    return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};
