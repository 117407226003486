import React, { useRef, useEffect, useState } from "react";

import useWidget from "../../../hooks/useWidget";
import Render from "zrender/render";
import { getDasUrl } from "../../../actions/config";
import { getDeviceWidth } from "../DesignUtils";
import { useSwipeable } from "react-swipeable";
import { processActions } from "../DesignUtils";

const UseWidgetSlider = ({ config, id, deviceType, data, goToSection }) => {
    const { styles, lang } = useWidget({ type: "SLIDER", styles: config ? config.styles : null });
    const configSlider = data?.config?.slider;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(false);
    const intervalRef = useRef(null);
    const [isReel, setIsReel] = useState(true);

    const deviceWidth = getDeviceWidth();
    const items = arrangeItems({
        items: configSlider.items || [],
        lang: lang,
        id: id,
        currentImage: currentImage,
        setCurrentImage: setCurrentImage,
        goToSection: goToSection,
    });

    const renderRef = useRef(new Render("SLIDER"));
    const render = renderRef.current;
    render.DAS = getDasUrl;

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (!isReel) {
                if (eventData.dir === "Left") {
                    updateIndex(currentIndex + 1);
                } else if (eventData.dir === "Right") {
                    updateIndex(currentIndex - 1);
                }
            } else {
                let container = document.getElementById(id);
                const margin = Array.from(container.getElementsByTagName("img"))
                    .map((el, index) => ({
                        x: el.x,
                        width: index == 0 || index == items.length - 1 ? el.width + 31 + 8 : el.width + 16,
                        index,
                    }))
                    .reduce(
                        (acc, curr, index, arr) => {
                            if (curr.x < 0) {
                                acc.margin += curr.width;
                            } else if (index > 0 && !acc.done) {
                                acc.index = index;
                                acc.done = true;
                            }
                            return acc;
                        },
                        { margin: 0, index: 0, done: false }
                    );
                updateIndex(margin.index);
                if (configSlider.automatic) {
                    setTimeout(() => {
                        container.scrollLeft = margin.margin;
                    }, 1000);
                }
            }
        },
        onSwipeStart: () => {
            if (isReel) {
                clearInterval(intervalRef.current);
            }
        },
        trackMouse: true,
    });

    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = 0;
        } else if (newIndex >= items.length) {
            newIndex = items.length - 1;
        }
        setCurrentIndex(newIndex);
        setCurrentImage(items[newIndex]);
    };

    function setIntervalAutomaticSimple() {
        if (configSlider?.automatic) {
            intervalRef.current = setInterval(
                () => {
                    if (items?.length > 1 && configSlider?.automatic) {
                        const next = currentIndex + 1 >= items.length ? 0 : currentIndex + 1;
                        if (configSlider?.mode === "reel") {
                            let container = document.getElementById(id);
                            const img = Array.from(container.getElementsByTagName("img")).map((el, index) => ({
                                x: el.x,
                                width: index == 0 || index == items.length - 1 ? el.width + 31 + 8 : el.width + 16,
                                index,
                            }));

                            if (next == 0) {
                                container.scrollLeft = 0;
                            } else {
                                container.scrollLeft += img[next].width;
                            }
                        }
                        setCurrentImage(items[next]);
                        setCurrentIndex(next);
                    }
                },
                configSlider?.interval && configSlider?.interval >= 5 ? configSlider.interval * 1000 : 5000
            );
        }
    }

    useEffect(() => {
        setIsReel(configSlider?.mode === "simple" ? false : true);
    }, [configSlider]);

    useEffect(() => {
        clearInterval(intervalRef.current);
        setIntervalAutomaticSimple();
    }, []);

    useEffect(() => {
        clearInterval(intervalRef.current);
        setIntervalAutomaticSimple();
    }, [currentIndex]);

    useEffect(() => {
        if (render) {
            render.setStyle(styles);
            render.setDevice(deviceType, deviceWidth);
            render.updateConfig({
                static: false,
                mode: configSlider?.mode || "simple",
                reelSize: configSlider?.reelSize || 27,
                lang: lang,
                items: items || [],
                swipeEvents: true,
                events: {
                    click: (e) => {
                        const indexBullet = e?.target?.dataset?.indexBullet || 0;
                        setCurrentIndex(Number(indexBullet));
                        setCurrentImage(items[indexBullet]);
                    },
                },
                id: id,
                imageSimple: {
                    currentImage: currentImage,
                    currentIndex: currentIndex,
                },
            });
            render.update();
        }
    }, [styles, deviceType, configSlider, currentImage]);

    useEffect(() => {
        const renderNode = document.getElementById(id);

        if (renderNode) {
            while (renderNode.firstChild) {
                renderNode.removeChild(renderNode.firstChild);
            }
            if (render) {
                renderNode.appendChild(render.run());
            }
        }
    }, [id]);

    return (
        <div
            {...handlers}
            id={id}
            style={{
                scrollBehavior: "smooth",
                height: "100%",
            }}
            className="w-full widget-slider-container overflow-x-scroll overflow-y-hidden h-full"
        ></div>
    );
};

export default UseWidgetSlider;

export const arrangeItems = ({ items, lang, setCurrentImage, currentImage, id, goToSection }) => {
    const { goToCuiner, goToLanding, goToRoomShop, landing } = goToSection;

    const finalItems = items
        .sort((a, b) => a.position - b.position)
        .filter((item) => item?.visibility)
        .map((item) => {
            let link = { target: null, id: null, href: null, onClick: null };
            link = processActions({
                data: { actions: arrangeActions(item) },
                id,
                landing,
                lang,
                goToLanding,
                goToCuiner,
                goToRoomShop,
                dasURL: getDasUrl(),
                link,
            });

            return {
                actions: arrangeActions(item),
                text: item.text.text,
                link: link,
                events: {
                    click: () => {
                        alert("click!");
                    },
                },
                src:
                    item?.resource?.libraryRef || item?.resource.externalUrl
                        ? { externalUrl: item?.resource.externalUrl, libraryRef: item?.resource?.libraryRef }
                        : null,
            };
        });

    if (!currentImage && finalItems.length > 0) {
        setCurrentImage(finalItems[0]);
    }

    return finalItems;
};

const arrangeActions = (item) => {
    return item.actions.filter((action) => !(action.type === null && action.value === ""));
};
