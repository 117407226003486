import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";
import { nl2br } from "./Design/DesignUtils";
import { getAppRef, getChainRef, getToken } from "../actions/config";
import { getToken as getUserToken } from "../actions/user";

const ErrorPage = ({ type, retry, title, desc, error }) => {
    const { t } = useTranslation();

    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const token = userToken ? userToken : appToken;
    const appRef = getAppRef();
    const chainRef = getChainRef();

    const [errorTouches, setErrorTouches] = useState(0);
    const showDebug = errorTouches >= 5;

    const errorDebug = (() => {
        if (error) {
            if (error instanceof ApolloError) {
                return JSON.stringify(error.graphQLErrors);
            } else if (error instanceof Error) {
                return error.stack;
            }
        }
        return null;
    })();

    const errorMessage =
        desc ||
        (() => {
            if (error) {
                if (error instanceof Error) {
                    return error.toString();
                } else {
                    return error;
                }
            }
            return null;
        })();

    const errorID = (() => {
        if (error) {
            if (error?.code) {
                return error.code;
            } else if (error instanceof ApolloError) {
                return "GQL_ERROR";
            } else if (error instanceof Error) {
                return "JS_ERROR";
            }
        }
        return null;
    })();
    const isOnline = navigator.onLine;
    const retryHandler = retry !== undefined ? retry : () => window.location.reload(false);

    let icon;
    switch (type) {
        case "wifi":
            icon = "wifi2-outline";
            break;
        case "config":
        case "config-terms":
            icon = "gear";
            break;
        case "404":
        case "no-portal":
            icon = "error404";
            break;
        case "server":
        default:
            icon = "server";
    }
    const errorTitle = title || t([`error.${type}`, "error.unknown"]);
    const errorDesc = errorMessage || t([`error.${type}Action`, "error.unknownAction"]);

    let delayedShow;
    useEffect(() => {
        delayedShow = setTimeout(() => {
            document.getElementById("errorPage").classList.remove("invisible");
        }, 3000);
        return () => {
            if (delayedShow) clearTimeout(delayedShow);
        };
    }, []);

    const copyAction = (e) => {
        try {
            e.target.focus();
            const range = document.createRange();
            range.selectNodeContents(e.target);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("copy");
            selection.removeAllRanges();
            e.target.blur();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <section id="errorPage" className="basic error-page flex invisible">
            <section className="text-center self-center">
                {showDebug ? null : (
                    <i
                        onClick={() => {
                            setErrorTouches(errorTouches > 10 ? 0 : errorTouches + 1);
                        }}
                        className={"icon icon-" + icon + " text-neutral-contrast-40"}
                        style={{ fontSize: "12rem" }}
                    ></i>
                )}

                <div className="text-xl mt-12 mb-16">
                    <div className="font-medium mb-3">{errorTitle}</div>
                    <div className=" break-words leading-6">{nl2br(errorDesc)}</div>
                    {errorID ? <div className="text-xs mt-5 leading-6">Error {errorID}</div> : null}
                    {showDebug && errorDebug ? (
                        <div
                            className="select-all text-left text-xs mt-5 leading-6 break-all text-error bg-white"
                            onClick={copyAction}
                        >
                            {nl2br(errorDebug)}
                        </div>
                    ) : null}
                    {showDebug ? (
                        <pre
                            className="select-all text-left text-xs mt-5 leading-6 break-all text-gray-700 bg-white"
                            onClick={copyAction}
                        >
                            {JSON.stringify(
                                {
                                    appRef,
                                    chainRef,
                                    portalWiFiTime: localStorage?.portalWiFiTime,
                                    portalWiFiOrigin: localStorage?.portalWiFiOrigin,
                                    tokens: {
                                        token,
                                        portalWiFi: localStorage?.portalWiFi,
                                    },
                                },
                                null,
                                2
                            )}
                        </pre>
                    ) : null}
                </div>
                {retryHandler ? (
                    <button onClick={isOnline ? retryHandler : null} className="btn btn-accent w-full text-lg">
                        {t("retry")}
                    </button>
                ) : null}
            </section>
        </section>
    );
};

export default ErrorPage;
